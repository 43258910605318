export default {
	name: "ListaAssunto",
	data() {
		return {
			arAssuntos: [],
			blLoading: true
		};
	},
	created() {
		this.listar();
	},
	methods: {
		listar() {
			this.$root.$api.get("assunto/listar").then(response => {
				this.arAssuntos = response.retorno;
				this.blLoading = false;
			});
		}
	}
};